"use client";
import React, { useEffect, useState } from "react";
import { useRouter, useSearchParams } from "next/navigation";
import { Margin } from "./library/Margin";
import { PincitesBanner, PincitesBannerType } from "./library/PincitesBanner";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import {
  createOrUpdateUser,
  OrganizationDisabledError,
  OrganizationNotFoundError,
  OrganizationRequiresInviteError,
} from "../api/PlaybookAPI";
import { NoActiveAccountError, getUserIDTokenOrThrow } from "../utils/authUtils";
import {
  PincitesFlexbox,
  PincitesFlexboxAlignItems,
  PincitesFlexboxDirection,
  PincitesFlexboxGap,
  PincitesFlexboxJustifyContent,
} from "./library/PincitesFlexbox";
import { Divider, Link, makeStyles, shorthands } from "@fluentui/react-components";
import { PincitesBadge, PincitesBadgeAppearance, PincitesBadgeColor } from "./library/PincitesBadge";
import { PincitesShadow, PincitesColor, PincitesBackgroundImage, PincitesBorderRadius } from "./library/PincitesTheme";
import { PincitesButton } from "./library/PincitesButton";
import { PincitesButtonType } from "../utils/buttonUtils";
import { PincitesSpinner, PincitesSpinnerSize } from "./library/PincitesSpinner";
import { PincitesLogo } from "./library/images/PincitesLogo";
import { MicrosoftLogo } from "./library/images/MicrosoftLogo";
import { PincitesText, PincitesTextType, PincitesFontType } from "./library/PincitesText";

const useStyles = makeStyles({
  container: {
    zIndex: -1,
    backgroundImage: PincitesBackgroundImage.WELCOME_PAGE,
    pointerEvents: "none",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  landingPageContent: {
    pointerEvents: "auto",
    backgroundColor: PincitesColor.NEUTRAL_BACKGROUND,
    borderRadius: PincitesBorderRadius.EXTRA_LARGE,
    ...shorthands.borderBottom(`4px solid ${PincitesColor.BRAND_BLUE}`),
    paddingBlock: "2rem",
    boxShadow: PincitesShadow.SHADOW16,
  },
  signInDivider: {
    width: "80%",
  },
  logoImage: {
    width: "80%",
    maxWidth: "300px",
    paddingBlock: "3rem",
  },
  demoLink: {
    color: PincitesColor.BRAND_BLUE,
    fontWeight: 400,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    display: "flex",
    gap: "0.5rem",
  },
  cardContent: {
    paddingInline: "0.5rem",
    "& > *": {
      whiteSpace: "nowrap",
    },
    "& > button": {
      minWidth: "60%",
    },
  },
  notSignedUpLink: {
    color: "inherit",
    display: "inline",
    ...shorthands.textDecoration("underline"),
    ":hover": {
      color: PincitesColor.WHITE_ALWAYS,
    },
  },
});

export function LandingPage(): React.JSX.Element {
  const router = useRouter();
  const { instance: msalInstance, accounts } = useMsal();
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const searchParams = useSearchParams();
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const styles = useStyles();

  const orgNotRegistered = searchParams.get("org_not_registered") != null;
  const orgRequiresInvite = searchParams.get("org_requires_invite") != null;
  const inviteCode = searchParams.get("invite-code") ?? undefined;

  useEffect(() => {
    async function fetchToken() {
      // If the org isn't registered, show the error banner and don't redirect
      if (orgNotRegistered || orgRequiresInvite) {
        setShowErrorBanner(true);
        setIsCheckingAuth(false);
        return;
      }

      // Otherwise, check if we have an active account
      if (!msalInstance.getActiveAccount()) {
        if (accounts.length == 1) {
          msalInstance.setActiveAccount(accounts[0]);
        } else {
          setIsCheckingAuth(false);
          return;
        }
      }

      // If we do, try to get a token silently
      getUserIDTokenOrThrow(msalInstance).then(
        async (token: string) => {
          // If we get a token, check if it's valid for Pincites
          try {
            await createOrUpdateUser({
              idToken: token,
              inviteCode: inviteCode,
            });

            // If we get here we're authenticated and ready to go!
            router.replace("/playbooks");
          } catch (error) {
            if (error instanceof OrganizationNotFoundError) {
              // The org isn't using Pincites. Redirect to the sign-in page with a query param
              router.replace("/?org_not_registered=true");
              return;
            }
            if (error instanceof OrganizationDisabledError) {
              // The org is using Pincites, but it's disabled. Redirect to the sign-in page with a query param
              router.replace("/?org_not_registered=true");
              return;
            }
            if (error instanceof OrganizationRequiresInviteError) {
              // The org requires users to be invited, and the current user hasn't been.
              // Redirect to the sign-in page with a query param
              router.replace("/?org_requires_invite=true");
              return;
            }
            setIsCheckingAuth(false);
            throw error;
          }
        },
        (error) => {
          setIsCheckingAuth(false);
          if (error instanceof InteractionRequiredAuthError || error instanceof NoActiveAccountError) {
            // Ignore interaction required errors - we're already on the log in page
            return;
          }
          throw error;
        },
      );
    }

    fetchToken();
  }, [msalInstance, accounts, router, orgNotRegistered, orgRequiresInvite, inviteCode]);

  const redirectToMsalLogin = async () => {
    if (isRedirecting) return;

    setIsRedirecting(true);
    await msalInstance.loginRedirect({
      scopes: ["User.Read"],
      redirectUri: "/redirect",
      prompt: orgNotRegistered ? "select_account" : undefined,
      state: inviteCode ? `invite-code=${inviteCode}` : undefined,
    });
    setIsRedirecting(false);
  };

  if (isCheckingAuth) {
    return <PincitesSpinner id="checking-auth-for-login" size={PincitesSpinnerSize.LARGE} pageLoadSpinner={true} />;
  }

  return (
    <div className={styles.container}>
      <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
        <PincitesFlexbox
          direction={PincitesFlexboxDirection.VERTICAL}
          justifyContent={PincitesFlexboxJustifyContent.CENTER}
          gap={PincitesFlexboxGap.GAP_8}
          alignItems={PincitesFlexboxAlignItems.CENTER}
          customClassName={styles.landingPageContent}
          id="landing-page-content"
        >
          <PincitesBanner
            isShown={showErrorBanner}
            content={
              orgNotRegistered ? (
                <OrganizationNotRegisteredBannerContent linkStyle={styles.notSignedUpLink} />
              ) : (
                <OrganizationRequiresInviteBannerContent />
              )
            }
            bannerType={PincitesBannerType.Error}
            isTemporary={false}
          />
          <PincitesFlexbox
            direction={PincitesFlexboxDirection.VERTICAL}
            justifyContent={PincitesFlexboxJustifyContent.CENTER}
            gap={PincitesFlexboxGap.GAP_12}
            alignItems={PincitesFlexboxAlignItems.CENTER}
            margin={[Margin.MARGIN_BOTTOM_16]}
            customClassName={styles.cardContent}
          >
            <Link
              className={styles.demoLink}
              href="https://calendly.com/pincites/demo"
              target="_blank"
              rel="noopener noreferrer"
              id="request-demo-link"
            >
              <PincitesBadge
                appearance={PincitesBadgeAppearance.FILLED}
                color={PincitesBadgeColor.BRAND}
                text="NEW HERE?"
              />
              Request demo
            </Link>
            <PincitesLogo
              className={styles.logoImage}
              width={"100%"}
              onClick={() => {
                router.push("https://pincites.com/");
              }}
            />
            <Divider className={styles.signInDivider}>Sign in with</Divider>
            <PincitesButton
              buttonType={PincitesButtonType.SECONDARY}
              icon={<MicrosoftLogo width={150} height={150} />}
              showSpinner={isRedirecting}
              onClick={redirectToMsalLogin}
              isDisabled={isRedirecting}
              label="Microsoft"
            />
          </PincitesFlexbox>
        </PincitesFlexbox>
      </div>
    </div>
  );
}

function OrganizationNotRegisteredBannerContent({ linkStyle }: { linkStyle: string }) {
  return (
    <PincitesText textType={PincitesTextType.BODY1} fontType={PincitesFontType.WHITE}>
      Your organization is not signed up.{" "}
      <Link href="https://calendly.com/pincites/demo" className={linkStyle}>
        Schedule a demo
      </Link>{" "}
      to get started.
    </PincitesText>
  );
}

function OrganizationRequiresInviteBannerContent() {
  return (
    <PincitesText textType={PincitesTextType.BODY1} fontType={PincitesFontType.WHITE}>
      Your organization requires admin approval of new Pincites users. Contact your legal ops team to get started.
    </PincitesText>
  );
}
