import { CheckTestRunResult } from "@/types/PlaybookTypes";
import { PincitesColor } from "../components/library/PincitesTheme";
import { Maybe } from "@/types/Types";
import { DiffChangeType, TTrackedChangeDiff } from "@/types/APITypes";

export function getColorForCheckResult(result: CheckTestRunResult): string {
  switch (result) {
    case CheckTestRunResult.FAILED:
      return PincitesColor.RED;
    case CheckTestRunResult.ERROR:
      return PincitesColor.MARIGOLD;
    case CheckTestRunResult.PASSED:
      return PincitesColor.GREEN_TEXT;
    case CheckTestRunResult.UNKNOWN:
      return PincitesColor.GRAY_TEXT;
    case CheckTestRunResult.NOT_RUN:
      return PincitesColor.GRAY_TEXT;
    case CheckTestRunResult.LOADING:
      return PincitesColor.BRAND_BLUE;
  }
}

export function getResultTestStatus(
  result: Maybe<CheckTestRunResult>,
  checkTestRunID: Maybe<string>,
): CheckTestRunResult {
  return result ?? (checkTestRunID ? CheckTestRunResult.LOADING : CheckTestRunResult.NOT_RUN);
}

export function getCurrentTextFromDiff(trackedChanges: TTrackedChangeDiff[]): string {
  return trackedChanges.reduce(
    (acc, diff) =>
      [DiffChangeType.no_change, DiffChangeType.insertion].includes(diff.change_type) ? acc + diff.text : acc,
    "",
  );
}
